import styled from 'styled-components';

export const FooterNavbar = styled.div`
  width: 100%;
  display: flex;
  height: 59px;
  z-index: 5;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  background: ${({theme}) => theme.colors.bgWhite};

  & > div {
    width: 1102px;
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;

    & > p {
      font-weight: 400;
      font-size: 11px;
      color: ${({theme}) => theme.colors.fontBlackOp100};
    }

    & > div {
      display: flex;
      gap: 42px;

      & > a {
        font-weight: 400;
        font-size: 11px;
        color: ${({theme}) => theme.colors.fontBlackOp100};
        text-decoration: none;
        cursor: pointer;
      }
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    & > div {
      width: 100%;
      padding: 0 12px;

      & > p {
        display: none;
      }

      & > div {
        gap: unset;
        width: 100%;
        justify-content: space-between;
      }
    }
  }
`;

export const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 88px;
  pointer-events: none;

  & > img {
    &:first-of-type {
      display: unset;
    }
    &:last-of-type {
      display: none;
    }
    position: relative;
    bottom: 0px;
    width: 100%;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    position: unset;
    pointer-events: unset;
    padding-bottom: 57px;

    & > img {
      &:first-of-type {
        display: none;
      }
      &:last-of-type {
        display: unset;
      }
      max-width: 412px;
    }
  }
`;

export const Content = styled.div`
  width: 896px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    /* width: 392px; */
    width: 100%;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    width: calc(100% - 14px);
  }
`;

export const Main = styled.div`
  pointer-events: all;
  width: 1020px;
  /* height: calc(100vh - 112px); */
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  margin-bottom: 20px;

  & > p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.92);
    line-height: 24px;
    margin-bottom: 10px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
    width: 420px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    width: 100%;
    /* display: none; */
  }

  /* @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    width: 100%;
    padding: unset;
  } */
`;

export const MobileSliderWrapper = styled.div`
  display: none;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: unset;
  }
`;

export const PageHeaderWrapper = styled.div`
  width: 1020px;
  display: flex;
  /* height: 152px; */
  flex-direction: column;
  align-items: center;
  border: 1px solid ${({theme}) => theme.colors.headerBorderColor};
  /* background: white; */
  border-radius: 24px 24px 24px 24px;
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.12);

  & > p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.92);
    line-height: 24px;
    margin-bottom: 10px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 420px;
    border-radius: 0px;
    display: none;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    width: 100%;
    /* display: none; */
  }
`;

export const PageHeaderHeaderWrapper = styled.div`
  width: 100%;
  height: 55px;
  border-radius: 24px 24px 0 0;
  background: ${({theme}) => theme.colors.pageHeader};
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const PageHeaderHeader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 9px;

  & > p {
    color: ${({theme}) => theme.colors.headerBlackFont};
    font-size: 15px;
    letter-spacing: 0.625px;
  }
`;

export const PageHeaderContent = styled.div`
  width: 100%;
  height: 386px;
  padding-bottom: 1px;
  background: ${({theme}) => theme.colors.headerContentBackground};
  border-radius: 0px 0px 24px 24px;
  display: grid;
  align-items: center;

  & > p {
    color: ${({theme}) => theme.colors.headerBlackFont};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    height: unset;
    aspect-ratio: 1;
    border-radius: 0px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    width: unset;
  }
`;

export const SectionWrapper = styled.div`
  width: 100%;
  height: 312px;
  display: flex;
  border: 1px solid ${({theme}) => theme.colors.fontBlackOp15};
  border-radius: 24px;
  flex-direction: column;
  position: relative;
  margin-top: 28px;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.12);

  & > h1 {
    margin-top: 23px;
    margin-bottom: 18px;
    padding-left: 43px;
    font-weight: 400;
    font-size: 24px;
    letter-spacing: -0.15px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }

  & > img {
    border-radius: 23px;
    width: 100%;
    height: 240px;

    &:first-of-type {
      display: unset;
    }

    &:last-of-type {
      display: none;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      &:first-of-type {
        display: none;
      }

      &:last-of-type {
        display: unset;
      }
    }
  }

  & > a {
    position: absolute;
    top: 22.5px;
    right: 36.6px;
    text-decoration: none;
    font-size: 11px;
    font-weight: 400;
    letter-spacing: -0.05px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: #0091ff;

    & > svg {
      margin-top: 2px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 392px;
    height: unset;
    padding-bottom: 10px;

    & > a {
      top: 25.5px;
      right: 24.1px;
      color: transparent;

      & > svg {
        width: 9.37px;
        height: 16px;
      }
    }

    & > h1 {
      width: 190px;
      font-size: 18px;
      font-weight: bold;
      /* text-align: left; */
      /* width: 100%; */
      margin-top: 13px;
      margin-bottom: 8px;
      padding-left: 22.5px;
    }

    & > img {
      border-radius: 23px;
      width: 372px;
      height: 248px;
      margin: 0 auto;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    width: 100%;

    & > img {
      width: 100%;
      height: unset;
    }

    & > h1 {
      padding-left: 20px;
    }
  }
`;

export const FirstSectionWrapper = styled(SectionWrapper)`
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding-bottom: 0px;

    & > img {
      border-radius: 23px;
      width: unset;
      height: unset;
      margin: unset;
    }
  }
`;

export const SecondSectionWrapper = styled(SectionWrapper)`
  border: none;
  background: #fb9614;
  height: 393px;
  cursor: pointer;

  & > img {
    height: 320px;
  }

  & > h1 {
    text-align: right;
    padding-left: unset;
    padding-right: 37px;
    width: 100%;
    text-align: right;
  }

  & > svg {
    position: absolute;
    left: 40px;
    top: 24px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    height: unset;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;

    & > img {
      width: 372px;
      height: 242px;
    }

    & > h1 {
      text-align: right;
      padding-left: unset;
      color: white;
      font-size: 18px;
      font-weight: bold;
      padding-right: 23px;
      width: 100%;
      margin-top: 14.4px;
      padding-left: 185px;
    }

    & > svg {
      position: absolute;
      left: 25px;
      top: 23px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    padding-bottom: 0px;

    & > h1 {
      padding-left: 140px;
    }

    & > img {
      width: 100%;
      height: unset;
    }
  }
`;

export const ThirdSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  border-radius: 24px;
  position: relative;
  background: #0091ff;
  margin-top: 28px;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.12);
  padding: 1.5px 2px;

  & > div {
    &:first-of-type {
      width: calc(100% - 376.5px);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 35px 43.5px 34px 52px;

      & > h1 {
        margin-bottom: 18px;
        font-weight: 400;
        font-size: 24px;
        letter-spacing: -0.15px;
        color: #17161d;

        &:last-of-type {
          display: none;
        }
      }

      & > p {
        margin-bottom: 25px;
        font-weight: 400;
        font-size: 13px;
        letter-spacing: -0.15px;
        color: #17161d;
        line-height: 24px;
        text-align: center;
      }

      & > div {
        color: #17161d;
        border: 1px solid #17161d;
        border-radius: 25px;
        width: 158px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        font-weight: bold;
        font-size: 11px;
        letter-spacing: -0.05px;
        cursor: pointer;

        & > svg {
          position: absolute;
          top: 12.5px;
          right: 21.5px;
        }
      }
    }

    &:last-of-type {
      border-radius: 22px;
      width: 376.5px;
      height: 251px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      & > div {
        position: absolute;
        color: #17161d;
        border: 1px solid #17161d;
        border-radius: 25px;
        width: 158px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        font-weight: bold;
        font-size: 11px;
        letter-spacing: -0.05px;
        cursor: pointer;

        & > svg {
          position: absolute;
          top: 12.5px;
          right: 21.5px;
        }
      }

      & > img {
        border-radius: 22px;
        width: 376.5px;
        height: 251px;
      }
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 392px;
    flex-direction: column;
    align-items: center;
    padding: 10px;

    & > div {
      &:first-of-type {
        width: 100%;
        padding: 5px 20px 8px;

        & > h1 {
          text-align: center;
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 9px;

          &:first-of-type {
            display: none;
          }
          &:last-of-type {
            display: unset;
          }
        }

        & > div {
          display: none;
        }

        & > p {
          width: 353px;
          text-align: center;
          margin-bottom: unset;
        }
      }

      &:last-of-type {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: unset;
        height: unset;
        background: transparent;
        border-radius: 18px;
        display: flex;
        align-items: center;
        justify-content: center;

        & > img {
          width: 372px;
          height: 242px;
          border-radius: 18px;
        }
      }
    }

    & > img {
      height: 248px;
      width: 389px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    width: 100%;

    & > img {
      width: 100%;
      height: unset;
    }

    & > div {
      &:first-of-type {
        width: 100%;
        padding: 35px 20px 52px;

        & > p {
          width: 100%;
          text-align: center;
        }
      }

      &:last-of-type {
        width: 100%;
        height: unset;

        & > img {
          width: 100%;
          height: unset;
        }
      }
    }
  }
`;

export const MobileTutorialButton = styled.button`
  display: none;
  position: absolute;
  width: 158px;
  height: 36px;
  border-radius: 25px;
  background: rgba(255, 255, 255, 0.7);
  border: 1px solid #17161d;
  position: absolute;
  /* bottom: 107px; */
  cursor: pointer;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: -0.05px;
  color: #17161d;

  & > svg {
    position: absolute;
    right: 25px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
  }
`;
